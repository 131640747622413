<template>
  <div style="max-height: 100%">
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      :label="$t('search')"
      single-line
      hide-details
      clearable
      class="mb-3"
    ></v-text-field>
    <p v-if="!pelottaGroupList.length">
      {{ $t("no-results") }}
    </p>
    <v-card
      v-for="pg in pelottaGroupList"
      :key="pg.name"
      class="mb-2"
      @click="addPelottaFromGroup(pg)"
    >
    <v-img :src="toDataUrl(pg.Items[0])" height="100" contain :style="getStyle(pg)" />
      <v-card-text class="text-center"> {{ $t(getName(pg)) }} </v-card-text>
    </v-card>
  </div>
</template>
<script>
import DesignMixin from "@/mixins/DesignMixin";

export default {
  name: "PelottaSelector",
  mixins: [DesignMixin],
  props: {
    side : {
      type: String
    }
  },
  data: () => ({ search: null }),
  computed: {
    pelottaGroupList() {
      var pgList = this.pelottaGroups.filter(
        pg => !this.search || pg.name.toLowerCase().includes(this.search)
      );
      
      pgList.push({name: "3", importanceIndex: 5, Items:["3"]});
      
      pgList.sort(this.pelottaGroupOrdering);
      return pgList;
    }
  },
  methods: {
    pelottaGroupOrdering(a, b)
    {
      var aN = a.importanceIndex ? -a.importanceIndex : 0;
      var bN = b.importanceIndex ? -b.importanceIndex : 0;
      
      if (a.name.length == 1)
      {
        aN += 10000;
      }
      
      if (b.name.length == 1)
      {
        bN += 10000;
      }
      
      if (aN > bN) {
        return -1.0;
      }
      else if (aN < bN) {
        return 1.0;
      }
      else {
        return 0.0;
      }
    },
    getName(p) {
      if (p.isBase)
      {
        return p.name + "_alap";
      }
      else
      {
        return p.name;
      }
    },
    getStyle(pg) {
      var scaleX = 1.0;
      var scaleY = 1.0;
      
      if (this.side == "left" && pg.name != "3")
      {
        scaleX *= -1;
      }
      
      return "transform: scale(" + scaleX + ", " + scaleY + ")";
    },
    toDataUrl(pelotta) {
      if (pelotta === "3")
      {
        return "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgeG1sbnM6c29kaXBvZGk9Imh0dHA6Ly9zb2RpcG9kaS5zb3VyY2Vmb3JnZS5uZXQvRFREL3NvZGlwb2RpLTAuZHRkIgogICB4bWxuczppbmtzY2FwZT0iaHR0cDovL3d3dy5pbmtzY2FwZS5vcmcvbmFtZXNwYWNlcy9pbmtzY2FwZSIKICAgd2lkdGg9IjUuNzU5NzY1Nm1tIgogICBoZWlnaHQ9IjkuMDc2MTcxOW1tIgogICB2aWV3Qm94PSItMiAtNCAxMS43NTk3NjU2IDEzLjA3NjE3MTgiCiAgIHZlcnNpb249IjEuMSIKICAgaWQ9InN2ZzgiPgogIDx0ZXh0CiAgICAgaWQ9InRleHQzIgogICAgIHg9Ii0wLjkxNDA2MjUiCiAgICAgeT0iOC45MDYyNSI+PHRzcGFuCiAgICAgICBzb2RpcG9kaTpyb2xlPSJsaW5lIgogICAgICAgaWQ9InRzcGFuODMzIgogICAgICAgeD0iLTAuOTE0MDYyNSIKICAgICAgIHk9IjguOTA2MjUiCiAgICAgICBzdHlsZT0iZm9udC1zdHlsZTpub3JtYWw7Zm9udC12YXJpYW50Om5vcm1hbDtmb250LXdlaWdodDpub3JtYWw7Zm9udC1zdHJldGNoOm5vcm1hbDtmb250LWZhbWlseTpzYW5zLXNlcmlmO3N0cm9rZS13aWR0aDowLjI2NDU4M3B4Ij4zPC90c3Bhbj48L3RleHQ+Cjwvc3ZnPgo=";
      }
      else
      {
        var data = pelotta.data;
        var swidth = Math.max(pelotta.widthMM, pelotta.heightMM) / 50;
        data = data.replace(/stroke-width:[^;]+;/g, "stroke-width:" + swidth + ";");
        return `data:image/svg+xml;base64,${btoa(data)}`;
      }
    }
  }
};
</script>
