<template>
  <div class="datasheet">
    <v-form @submit.prevent="validSave" ref="form" v-model="formValid">
      <v-row>
        <v-col cols="8">
          <v-card :loading="!localRecord">
            <template v-if="!localRecord">
              <v-card-text class="text-center">
                {{ $t("loading") }}
              </v-card-text>
            </template>
            <template v-else>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field
                      disabled
                      :label="$t('id')"
                      :value="localRecord.PatientID"
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      disabled
                      :label="$t('registered-at')"
                      :value="
                        new Date(localRecord.dateTime).toLocaleString(
                          $i18n.locale
                        )
                      "
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      disabled
                      :label="$t('reorder')"
                      :value="localRecord.followupOfPatientID || ' '"
                    />
                  </v-col>
                </v-row>
              </v-card-text>

              <v-toolbar class="green lighten-5" flat dense>
                <span class="title">
                  {{ $t("personal-data") }}
                </span>
              </v-toolbar>

              <v-card-text>
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                      :label="$t('name')"
                      :rules="[rules.required, rules.counter(50)]"
                      :counter="50"
                      v-model="localRecord.name"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="localRecord.sex"
                      :rules="[rules.required]"
                      :label="`${$t('sex')}:`"
                      row
                    >
                      <v-radio :label="$t('male')" value="male"></v-radio>
                      <v-radio :label="$t('female')" value="female"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      :label="$t('country')"
                      :rules="[rules.required]"
                      v-model="localRecord.address.country"
                      value="Magyar"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-combobox
                      type="number"
                      :label="$t('zipcode')"
                      :rules="[rules.required]"
                      v-model="localRecord.address.zipCode"
                      :items="suggestions.zip"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      :label="$t('city')"
                      :rules="[rules.required]"
                      v-model="localRecord.address.city"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-combobox
                      :label="$t('address')"
                      :rules="[rules.required]"
                      v-model="localRecord.address.streetAddress"
                      :items="suggestions.street(localRecord.address.zipCode)"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-menu
                      transition="scale-transition"
                      v-model="birtDateSelector"
                      offset-y
                      min-width="auto"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :label="$t('birthdate')"
                          v-model="localRecord.birthDate"
                          :rules="[rules.required]"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          @click="birtDateSelector = true"
                        />
                      </template>
                      <v-date-picker
                        @change="birtDateSelector = false"
                        v-model="localRecord.birthDate"
                        no-title
                        scrollable
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      type="number"
                      :label="$t('height')"
                      :rules="[rules.required]"
                      v-model="localRecord.height"
                      suffix="cm"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      type="number"
                      :label="$t('weight')"
                      :rules="[rules.required]"
                      v-model="localRecord.weight"
                      suffix="kg"
                    >
                      <template #append-outer>
                        <v-btn
                          small
                          depressed
                          color="primary"
                          :loading="measureingWeight"
                          :disabled="
                            !(
                              $store.state.user &&
                              $store.state.user.type === 'user'
                            )
                          "
                          @click="getWeightFromBox"
                          :title="$t('measure-weight')"
                        >
                          {{ $t("measure") }}
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      type="tel"
                      :label="$t('phone')"
                      :rules="[rules.required]"
                      v-model="localRecord.phoneNumber"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      type="email"
                      :label="$t('email')"
                      v-model="localRecord.email"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      type="email"
                      :label="$t('email2')"
                      v-model="localRecord.email2"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      v-model="localRecord.sendTo"
                      :rules="[rules.required]"
                      :label="`${$t('send-to')}:`"
                      row
                    >
                      <v-radio :label="$t('shop')" value="shop"></v-radio>
                      <v-radio :label="$t('client')" value="client"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <v-btn @click="downloadGDPR">
                      {{ $t("gdpr-download") }}
                    </v-btn>
                    <v-checkbox
                      @click="downloadGDPR"
                      v-model="localRecord.gdpr_generated"
                      :label="$t('gdpr-downloaded')"
                      :rules="[rules.required]"
                      readonly
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-combobox
                      v-model="localRecord.diagnosis"
                      :items="diagnosis"
                      item-value="id"
                      :item-text="item => $tu(item)"
                      :label="$t('diagnosis')"
                      multiple
                      chips
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          :key="JSON.stringify(data.item.id)"
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          :disabled="data.disabled"
                          @click:close="data.parent.selectItem(data.item)"
                          close
                        >
                          {{ $tu(data.item) }}
                        </v-chip>
                      </template>
                      <template #append-outer>
                        <v-dialog
                          v-model="diagnosisDialog"
                          transition="dialog-bottom-transition"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon>mdi-view-dashboard</v-icon>
                            </v-btn>
                          </template>
                          <v-card>
                            <v-toolbar dark color="primary">
                              <v-toolbar-title>
                                {{ $t("diagnosis") }}
                              </v-toolbar-title>
                              <v-spacer></v-spacer>
                              <v-toolbar-items>
                                <v-btn
                                  icon
                                  dark
                                  @click="diagnosisDialog = false"
                                >
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-toolbar-items>
                            </v-toolbar>
                            <v-container fluid>
                              <v-row>
                                <template v-for="d in diagnosis">
                                  <template v-if="d.header">
                                    <v-col cols="12" :key="`group_${d.header}`">
                                      <v-card class="green lighten-5" flat>
                                        <v-card-title>
                                          {{ $tu(d) }}
                                        </v-card-title>
                                      </v-card>
                                    </v-col>
                                  </template>
                                  <template v-else>
                                    <v-col cols="3" :key="d.id">
                                      <v-card
                                        :dark="isSelected(d)"
                                        :color="isSelected(d) ? 'primary' : ''"
                                        @click="toggleDiagnosis(d)"
                                      >
                                        <v-card-text>
                                          {{ $tu(d) }}
                                        </v-card-text>
                                      </v-card>
                                    </v-col>
                                  </template>
                                </template>
                              </v-row>
                            </v-container>
                          </v-card>
                        </v-dialog>
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      rows="2"
                      counter
                      :label="$t('comment')"
                      v-model="localRecord.comment"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                    rows="2"
                    counter
                    :label="$t('insurance-data')"
                    v-model="localRecord.privateInsurance"
                    > </v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-toolbar class="green lighten-5" flat dense>
                <v-btn
                  text
                  v-if="$store.state.user.type=='tech'"
                  @click="downloadPdfReport"
                >
                  {{ $t("print-datasheet") }}
                </v-btn>
                <v-btn
                  text
                  v-if="$store.state.user.type=='tech'"
                  color="primary"
                  @click="sendPdfReport"
                >
                {{ $t("send-datasheet-via-email") }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  :disabled="!changed"
                  type="submit"
                  :loading="saving"
                >
                  {{ $t("save") }}
                </v-btn>
                <v-btn
                  color="error"
                  text
                  :disabled="!changed || saving"
                  @click="discard"
                >
                  {{ $t("discard") }}
                </v-btn>
              </v-toolbar>
            </template>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card v-if="localRecord">
            <v-toolbar class="green lighten-5" flat dense>
              <span class="title">
                {{ $t("pain-map") }}
              </span>
            </v-toolbar>
            <div class="painMap">
              <v-checkbox
                style="position: absolute; z-index: 2"
                v-for="(spot, i) in getPainSpotsStrcutre()"
                v-model="localRecord.painMap[spot.id]"
                :key="`pain-spot-${i}`"
                dark
                dense
                color="#000000"
                v-bind="spot"
              />
              <v-img :src="talpref" class="rounded-b"></v-img>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import RecordMixin from "@/mixins/RecordMixin";
import OptionsMixin from "@/mixins/OptionsMixin";
import SecureSaveMixin from "@/mixins/SecureSaveMixin";
import talpref from "@/assets/talpref.jpg";
import hunZipCodes from "@/assets/hunZipCodes";

export default {
  name: "Datasheet",
  mixins: [RecordMixin, OptionsMixin, SecureSaveMixin],
  props: {},
  data: () => ({
    talpref,
    measureingWeight: false,
    hunZipCodes,
    diagnosisDialog: false,
    birtDateSelector: false,
    formValid: true,
    painSpots: [
      {
        id: "FR1",
        title: "Lábujj 1 (Jobb láb)",
        style: "top: 22.6%; left: 2.9%"
      },
      {
        id: "FR2",
        title: "Lábujj 2 (Jobb láb)",
        style: "top: 17.3%; left: 9.3%"
      },
      {
        id: "FR3",
        title: "Lábujj 3 (Jobb láb)",
        style: "top: 13.2%; left: 18.0%"
      },
      {
        id: "FR4",
        title: "Lábujj 4 (Jobb láb)",
        style: "top: 11.2%; left: 27.3%"
      },
      {
        id: "FR5",
        title: "Lábujj 5 (Jobb láb)",
        style: "top: 10.7%; left: 39.0%"
      },
      {
        id: "FR6",
        title: "Középcsont 5 (Jobb láb)",
        style: "top: 35.5%; left:  8.9%"
      },
      {
        id: "FR7",
        title: "Középcsont 4 (Jobb láb)",
        style: "top: 31.6%; left: 13.8%"
      },
      {
        id: "FR8",
        title: "Középcsont 3 (Jobb láb)",
        style: "top: 27.9%; left: 19.3%"
      },
      {
        id: "FR9",
        title: "Középcsont 2 (Jobb láb)",
        style: "top: 25.6%; left: 26.2%"
      },
      {
        id: "FR10",
        title: "Középcsont 1 (Jobb láb)",
        style: "top: 28.0%; left: 32.6%"
      },
      {
        id: "FR11",
        title: "Talp (Jobb láb)",
        style: "top: 52.5%; left: 19.6%"
      },
      {
        id: "FR12",
        title: "Sarok (Jobb láb)",
        style: "top: 87.3%; left: 24.4%"
      },
      {
        id: "FR13",
        title: "Sarokcsontkinövés, sarkantyú (Jobb láb)",
        style: "top: 94.2%; left: 16.2%"
      },
      {
        id: "FL1",
        title: "Lábujj 1 (Bal láb)",
        style: "top: 22.6%; left: 89.9%"
      },
      {
        id: "FL2",
        title: "Lábujj 2 (Bal láb)",
        style: "top: 17.3%; left: 83.5%"
      },
      {
        id: "FL3",
        title: "Lábujj 3 (Bal láb)",
        style: "top: 13.2%; left: 74.8%"
      },
      {
        id: "FL4",
        title: "Lábujj 4 (Bal láb)",
        style: "top: 11.2%; left: 65.5%"
      },
      {
        id: "FL5",
        title: "Lábujj 5 (Bal láb)",
        style: "top: 10.7%; left: 53.8%"
      },
      {
        id: "FL6",
        title: "Középcsont 5 (Bal láb)",
        style: "top: 35.5%; left: 83.9%"
      },
      {
        id: "FL7",
        title: "Középcsont 4 (Bal láb)",
        style: "top: 31.6%; left: 79.0%"
      },
      {
        id: "FL8",
        title: "Középcsont 3 (Bal láb)",
        style: "top: 27.9%; left: 73.5%"
      },
      {
        id: "FL9",
        title: "Középcsont 2 (Bal láb)",
        style: "top: 25.6%; left: 66.6%"
      },
      {
        id: "FL10",
        title: "Középcsont 1 (Bal láb)",
        style: "top: 28.0%; left: 60.2%"
      },
      { id: "FL11", title: "Talp (Bal láb)", style: "top: 52.5%; left: 73.2%" },
      {
        id: "FL12",
        title: "Sarok (Bal láb)",
        style: "top: 87.3%; left: 68.4%"
      },
      {
        id: "FL13",
        title: "Sarokcsontkinövés, sarkantyú (Jobb láb)",
        style: "top: 94.2%; left: 76.6%"
      },
      { id: "S1", title: "Nyak és Hát", style: "top: 30.0%; left: 45.9%" },
      { id: "S2", title: "Csípő", style: "top: 38.0%; left: 45.9%" },
      { id: "S3", title: "Derék", style: "top: 46.0%; left: 45.9%" },
      { id: "KR", title: "Térd (Jobb láb)", style: "top: 54.0%; left: 40.9%" },
      { id: "KL", title: "Térd (Bal láb)", style: "top: 54.0%; left: 50.9%" },
      {
        id: "LR1",
        title: "Lábikra (Jobb láb)",
        style: "top: 62.0%; left: 40.9%"
      },
      {
        id: "LL1",
        title: "Lábikra (Bal láb)",
        style: "top: 62.0%; left: 50.9%"
      },
      {
        id: "LR2",
        title: "Sajkacsont (Jobb láb)",
        style: "top: 66.0%; left: 30.9%"
      },
      {
        id: "LL2",
        title: "Sajkacsont (Bal láb)",
        style: "top: 66.0%; left: 62.9%"
      },
      { id: "AR", title: "Boka (Jobb láb)", style: "top: 78.0%; left: 36.9%" },
      { id: "AL", title: "Boka (Bal láb)", style: "top: 78.0%; left: 54.9%" }
    ]
  }),
  mounted() {
    if (this.localRecord.address.country === undefined)
    {
      this.localRecord.address.country = this.$t("hungary");
    }
    this.$on("validateForm", this.$refs.form.validate);
  },
  
  computed: {
    rules() {
      return {
        required: value => !!value || this.$t("required-field"),
        counter: n => value =>
          !value || value.length <= n || this.$t("max-n-characters", { n }),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t("invalid-e-mail");
        }
      };
    },
    suggestions() {
      // TODO : consider the country to provide zipcode list
      return {
        zip: hunZipCodes.map(entry => entry.zip),
        street: zip =>
          hunZipCodes
            .filter(entry => entry.zip === zip && entry.streetName)
            .map(entry => `${entry.streetName} ${entry.streetType} `)
      };
    },
    diagnosis() {
      if (!this.options.Diagnosis) return [];
      return Object.values(this.options.Diagnosis).reduce((acc, cur) => {
        acc.push({ header: this.$tu(cur.CategoryName), ...cur.CategoryName });
        acc.push(...cur.CategoryItems);
        return acc;
      }, []);
    }
  },
  methods: {
    async getWeightFromBox() {
      this.measureingWeight = true;
      try {
        this.localRecord = {
          ...this.localRecord,
          weight: await this.$store.dispatch("getBoxWeight")
        };
      } catch (error) {
        console.log(error);
      }
      this.measureingWeight = false;
    },
    async validSave() {
      const valid = await this.$refs.form.validate();
      if (valid) this.save();
    },
    getPainSpotsStrcutre() {
      var that = this;
      var result = this.painSpots.map(function(spot) {
        spot.title = that.$t("Pain"+spot.id);
        return spot;
      });
      return result;
    },
    isSelected(d) {
      if (!this.localRecord || !this.localRecord.diagnosis) return false;
      return (
        this.localRecord.diagnosis.findIndex(item => item.id === d.id) > -1
      );
    },
    toggleDiagnosis(d) {
      if (!this.localRecord) return;
      if (!this.localRecord.diagnosis) this.localRecord.diagnosis = [];
      const idx = this.localRecord.diagnosis.findIndex(
        item => item.id === d.id
      );
      if (idx > -1) {
        this.localRecord.diagnosis.splice(idx, 1);
      } else {
        this.localRecord = {
          ...this.localRecord,
          diagnosis: [...this.localRecord.diagnosis, d]
        };
      }
    },
    async downloadPdfReport() {
      if (!await this.secureSavePatientRecord(this.localRecord, true)) {
        return 
      }
      
      //TODO: refactor this to the store
      var response = await this.$store.dispatch("pdfReport", this.localRecord.PatientID);
      console.log(response);
      var PatientID = response.PatientID;
      var pdfURL = response.link;
      
      try {
        
        response = await fetch(pdfURL);
        var pdfBlob = await response.blob();
        
        const data = window.URL.createObjectURL(pdfBlob);
        var link = document.createElement('a');
        
        link.href = data;
        link.download = PatientID + "_" + this.$t("report_sheet") + ".pdf";
        link.onclick = function(){window.URL.revokeObjectURL(pdfBlob);}
        link.click();
      } catch (err)
      {
        window.open(pdfURL);
      }
    },
    async sendPdfReport() {
      if (!await this.secureSavePatientRecord(this.localRecord, true)) {
        return 
      }
      
      await this.$store.dispatch("sendEmail", this.localRecord.PatientID);
    },
    async downloadGDPR() {
      this.localRecord.gdpr_generated = true;
      this.$forceUpdate();
      
      if (!await this.secureSavePatientRecord(this.localRecord, true)) {
        return 
      }
      
      //TODO: refactor this to the store
      var response = await this.$store.dispatch("pdfGDPR", this.localRecord.PatientID);
      console.log(response);
      var PatientID = response.PatientID;
      var pdfURL = response.link;
      
      try {
        
        response = await fetch(pdfURL);
        var pdfBlob = await response.blob();
        
        const data = window.URL.createObjectURL(pdfBlob);
        var link = document.createElement('a');
        
        link.href = data;
        link.download = PatientID + "_" + this.$t("GDPR") + ".pdf";
        link.onclick = function(){window.URL.revokeObjectURL(pdfBlob);}
        link.click();
        
      } catch (err)
      {
        window.open(pdfURL);
      }
    }
  },
  watch: {
    "localRecord.address.zipCode"(v) {
      const codeEntry = this.hunZipCodes.find(c => c.zip === parseInt(v));
      if (codeEntry && !this.localRecord.address.city)
        this.localRecord.address.city = codeEntry.city;
    },
    formValid(v) {
      this.preventSaving = !v;
    }
  }
};
</script>
