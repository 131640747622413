import budapest from "hu-postal-codes/budapest.json";
import miskolc from "hu-postal-codes/miskolc.json";
import debrecen from "hu-postal-codes/debrecen.json";
import szeged from "hu-postal-codes/szeged.json";
import pecs from "hu-postal-codes/pecs.json";
import gyor from "hu-postal-codes/gyor.json";
import other from "hu-postal-codes/postal-codes.json";

const romanize = num => {
  const lookup = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1
  };
  let roman = "";
  for (let i in lookup) {
    while (num >= lookup[i]) {
      roman += i;
      num -= lookup[i];
    }
  }
  return roman;
};

const codes = [
  ...budapest.map(entry => ({
    city: `Budapest, ${romanize(entry.district)}`,
    ...entry
  })),
  ...miskolc.map(entry => ({ city: `Miskolc`, ...entry })),
  ...debrecen.map(entry => ({ city: `Debrecen`, ...entry })),
  ...szeged.map(entry => ({ city: `Szeged`, ...entry })),
  ...pecs.map(entry => ({ city: `Pécs`, ...entry })),
  ...gyor.map(entry => ({ city: `Győr`, ...entry })),
  ...other
];

codes.sort((a, b) => a.zip - b.zip);

export default codes;
