import { mapState, mapGetters, mapActions } from "vuex";
import store from "../store";
import { OrderStatus } from "@/assets/static-options";

const getters = Object.keys(store.getters)
  .filter(g => g.startsWith("optionsModule/"))
  .map(g => g.substring(g.indexOf("/") + 1));

export default {
  mounted() {
    this.fetchAllOptions();
  },
  computed: {
    ...mapGetters("optionsModule", getters),
    ...mapState(["coverImages"]),
    options() {
      return {
        ...Object.fromEntries(getters.map(key => [key, this[key]])),
        OrderStatus: OrderStatus.map(value => ({
          text: this.$t(value),
          value
        })),
        OrderCover:
          (this.localImageList &&
            this.localImageList.map(cover => cover.coverCode)) ||
          []
      };
    },
    localImageList() {
      if (!this.coverImages) {
        this.fetchCoverImageList();
        return null;
      }

      var result = [];

      for (var coverCode in this.coverImages.Images) {
        result.push({
          coverImage: this.coverImages.Images[coverCode],
          coverCode: coverCode,
          caption: this.coverImages.Captions[coverCode]
        });
      }

      return result;
    }
  },
  methods: {
    ...mapActions(["fetchCoverImageList", "deleteCoverImage"]),
    fetchAllOptions(force = false) {
      const options = this.options;
      for (const [key, value] of Object.entries(options)) {
        if (key.endsWith("Localized")) continue;
        if (!value || force)
          this.$store.dispatch("optionsModule/fetchOptions", key);
      }
    },
    getOptionById(id, returnObject = false) {
      if (!id) return returnObject ? null : "";
      const type = id.substring(0, id.indexOf("_"));
      if (returnObject) {
        const items = this[type];
        const item = (items || []).find(i => i.id === id);
        return item || null;
      }
      const items = this[type + "Localized"];
      const item = (items || []).find(i => i.value === id);
      return (item && item.text) || `"${id}"`;
    }
  }
};
