import { mapState, mapGetters } from "vuex";
import { matchPelottaWithSize } from "@/utils/pelottaHelper";

//const feetCameraDPM = 3.7795;

const cloneDeep = require("lodash.clonedeep");

export default {
  props: {
    design: {
      required: true
    },
    order: {
      required: true
    }
  },
  data: () => ({ localDesign: null }),
  mounted() {
    if (!this.pelottas || !this.pelottas.length) {
      this.$store.dispatch("listPelottas");
    }
    this.localDesign = cloneDeep(this.design);
  },
  computed: {
    ...mapState(["pelottas"]),
    ...mapGetters(["pelottaGroups"])
  },
  methods: {
    update() {
      this.$emit("update:design", this.localDesign);
    },
    pushPelotta(p)
    {
      var index = this.localDesign.pelottas.length;
      this.localDesign.pelottas.push(p);
      this.update();
      return index;
    },
    pickPelotta(p)
    {
      this.localDesign.pickedPelotta = p;
      this.update();
    },
    getPickedPelotta()
    {
      var p = this.localDesign.pickedPelotta;
      this.localDesign.pickedPelotta = undefined;
      if (p)
      {
        this.update();
      }
      return p;
    },
    updateComputedData() {
      this.localDesign.pelottas.map((p) => {
        const pelottaData = this.pelottas.find(
          ({ PelottaID }) => PelottaID === p.PelottaID
        );
        
        if (!pelottaData) {
          return;
        }
        
        let data = pelottaData.data;
        
        p.widthMM = pelottaData.widthMM;
        p.heightMM = pelottaData.heightMM;
        
        p.data = data;
        p.helperVertices = pelottaData.helperVertices;
        
        p.scaleX = 1.0;
        p.scaleY = 1.0;
        
        if (p.mirrored)
        {
          p.scaleX *= -1.0;
        }
        else
        {
          p.scaleX *= 1.0
        }
        
        if (p.side == "left")
        {
          p.scaleX *= -1.0;
        }
        else
        {
          p.scaleX *= 1.0
        }
      });
    },
    rotateOffset(x, y, deg)
    {
      var x2 = (x)*Math.cos(deg/180*Math.PI) - (y)*Math.sin(deg/180*Math.PI);
      var y2 = (x)*Math.sin(deg/180*Math.PI) + (y)*Math.cos(deg/180*Math.PI);
      
      return {x2, y2};
    },
    add3PelottaSet(base) {
      var sizeX = base.widthMM * base.xPixelPerMM;
      var sizeY = base.heightMM * base.yPixelPerMM;
      
      var scale = sizeY/913;
      
      console.log(sizeX, sizeY);
      
      if (this.localDesign.pelottas.find((p) => p.rootName == "hosszem" && p.side == base.side) === undefined)
      {
        const targetGroup1 = this.pelottaGroups.find((pg) => pg.name == "hosszem");
  //       const targetGroup1 = this.pelottaGroups.find((pg) => pg.name == "horiz");
        this.addPelottaFromGroup(targetGroup1);
        let pickedPelotta1 = this.getPickedPelotta();
        pickedPelotta1.side = base.side;
        let x = 0.0;
        let y = 372;
        let {x2,y2} = this.rotateOffset(x, y, base.rotation);
//         console.log(x2, y2);
        pickedPelotta1.x = base.x + x2*scale;
        pickedPelotta1.y = base.y + y2*scale;
        pickedPelotta1.rotation = base.rotation;
        this.pushPelotta(pickedPelotta1);
      }

      if (this.localDesign.pelottas.find((p) => p.rootName == "vese" && p.side == base.side) === undefined)
      {
        const targetGroup2 = this.pelottaGroups.find((pg) => pg.name == "vese");
  //       const targetGroup2 = this.pelottaGroups.find((pg) => pg.name == "horiz");
        this.addPelottaFromGroup(targetGroup2);
        let pickedPelotta2 = this.getPickedPelotta();
        pickedPelotta2.side = base.side;
        let x = 88.67*base.scaleX;
        let y = 183.95;
        let {x2,y2} = this.rotateOffset(x, y, base.rotation);
        pickedPelotta2.x = base.x + x2*scale; 
        pickedPelotta2.y = base.y + y2*scale;
        pickedPelotta2.rotation = base.rotation;
        this.pushPelotta(pickedPelotta2);
      }
      
      if (this.localDesign.pelottas.find((p) => p.rootName == "kbemelo" && p.side == base.side) === undefined)
      {
        const targetGroup3 = this.pelottaGroups.find((pg) => pg.name == "kbemelo");
  //       const targetGroup3 = this.pelottaGroups.find((pg) => pg.name == "horiz");
        this.addPelottaFromGroup(targetGroup3);
        let pickedPelotta3 = this.getPickedPelotta();
        pickedPelotta3.side = base.side;
        let x = 160.32*base.scaleX;
        let y = 405.27;
        let {x2,y2} = this.rotateOffset(x, y, base.rotation);
        pickedPelotta3.x = base.x + x2*scale;
        pickedPelotta3.y = base.y + y2*scale;
        pickedPelotta3.rotation = 10 * base.scaleX + base.rotation;
        this.pushPelotta(pickedPelotta3);
      }
    },
    addPelottaFromGroup( pelottaGroup ) {
      if (pelottaGroup.name === "3")
      {
        const leftBase = this.localDesign.pelottas.find((p) => p.isBase && p.side == "left");
        const rightBase = this.localDesign.pelottas.find((p) => p.isBase && p.side == "right");
        
        this.add3PelottaSet(leftBase);
        this.add3PelottaSet(rightBase);
        
        return;
      }
      
      const orderShoeSize = parseFloat(this.side == "left" ? this.order.shoeSizeLeft : this.order.shoeSizeRight);
      
      for (var i = 0; i < pelottaGroup.Items.length; i++)
      {
        //console.log(pelottaGroup.Items[i].sizeMin, pelottaGroup.Items[i].sizeMax, pelottaGroup.Items[i].size);
        
        if (pelottaGroup.Items[i].size == orderShoeSize)
        {
          return this.addPelotta(pelottaGroup.Items[i]);
        }
        
        if (
          pelottaGroup.Items[i].sizeMin && pelottaGroup.Items[i].sizeMax &&
          pelottaGroup.Items[i].sizeMin <= orderShoeSize && 
          pelottaGroup.Items[i].sizeMax >= orderShoeSize
        )
        {
          return this.addPelotta(pelottaGroup.Items[i]);
        }
        
        if (matchPelottaWithSize(pelottaGroup.Items[i].name, pelottaGroup.Items[i].size, orderShoeSize))
        {
          return this.addPelotta(pelottaGroup.Items[i]);
        }
      }
      
      return this.addPelotta(pelottaGroup.Items[0]);
    },
    addPelotta({ PelottaID }) {
      var pelotta = this.pelottas.find((p) => p.PelottaID == PelottaID);
      console.log(pelotta);
      
      var UID = "P" + String(Math.random()) + new Date().getTime();
      
      var p = { PelottaID, x: 0, y: 0, side: this.side, mirrored: false, rotation: 0.0, data: "", UID: UID, locked: false, rootName: pelotta.name, sizeCode: pelotta.size, isBase : pelotta.isBase, widthMM: pelotta.widthMM, heightMM : pelotta.heightMM, helperVertices: pelotta.helperVertices, viewx1MM: pelotta.viewx1MM, viewx2MM: pelotta.viewx2MM, viewy1MM: pelotta.viewy1MM, viewy2MM: pelotta.viewy2MM, centerxMM: pelotta.centerxMM, centeryMM: pelotta.centeryMM};
      
      console.log("new object:");
      console.log(p);
      
      this.pickPelotta(p);
      //this.localDesign.pickedPelotta = p;
      //this.update();
    },
    checkAndPlaceBasePlates() {
      const leftBase = this.localDesign.pelottas.find((p) => p.isBase && p.side == "left");
      const rightBase = this.localDesign.pelottas.find((p) => p.isBase && p.side == "right");
      
      if (leftBase == undefined)
      {
        console.log("Missing left base, adding");
        const baseGroup = this.pelottaGroups.find((pg) => pg.name == this.order.baseType);
        if (baseGroup)
        {
          this.addPelottaFromGroup(baseGroup);
          let pickedPelotta = this.getPickedPelotta();
          pickedPelotta.side = "left";
          pickedPelotta.x = 400 - pickedPelotta.widthMM / this.widthMM * this.width / 2 * (pickedPelotta.side == "left" ? -1 : 1);
          pickedPelotta.y = 577 - pickedPelotta.heightMM / this.heightMM * this.height / 2;
          this.pushPelotta(pickedPelotta);
        }
      }
      
      if (rightBase == undefined)
      {
        console.log("Missing right base, adding");
        const baseGroup = this.pelottaGroups.find((pg) => pg.name == this.order.baseType);
        if (baseGroup)
        {
          this.addPelottaFromGroup(baseGroup);
          let pickedPelotta = this.getPickedPelotta();
          pickedPelotta.side = "right";
          pickedPelotta.x = 1200 - pickedPelotta.widthMM / this.widthMM * this.width / 2 * (pickedPelotta.side == "left" ? -1 : 1);
          pickedPelotta.y = 577 - pickedPelotta.heightMM / this.heightMM * this.height / 2;
          this.pushPelotta(pickedPelotta);
        }
      }
    }
  },
  watch: {
    design: {
      deep: true,
      handler() {
        //console.log("design event");
        this.localDesign = cloneDeep(this.design);
      }
    },
    pelottaGroups : {
      deep: true,
      handler() {
        console.log("pelottaGroups", this.pelottaGroups.length, this.localDesign);
        this.checkAndPlaceBasePlates(); //we call this in case we had a race between mounting the konva designer and loading the pelottas
      }
    }
  }
};
