export default {
  methods: {
    haha() {
    },
    async secureSavePatientRecord(patient, quickSave) {
      console.log(JSON.stringify(patient));
      try {
        if (quickSave)
        {
          await this.$store.dispatch("quickSaveRecord", patient);
        }
        else
        {
          await this.$store.dispatch("updateRecord", patient);
        }
        
        return true;
      } catch(except)
      {
        console.error(except);
        if (await this.$confirm(this.$t("save-failed-retry"), {
          title: this.$t("error"),
          buttonTrueText: this.$t("yes"),
          buttonTrueColor: "error",
          buttonFalseText: this.$t("cancel")
        }))
        {
          return await this.secureSavePatientRecord(patient, quickSave);
        }
        else
        {
          return false;
        }
      }
    }
  }
};
