var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.localDesign)?_c('div',[_c('div',{staticClass:"rounded",staticStyle:{"overflow":"hidden"},attrs:{"id":("konva-parent-" + _vm._uid)}},[_c('table',{staticStyle:{"margin-left":"auto","margin-right":"auto"}},[_c('tr',[(!_vm.preview)?_c('td',{staticStyle:{"width":"200px"},attrs:{"valign":"bottom","align":"right"}},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.getSortedPelottaListSide('left')),function(p){return _c('v-list-item',{key:p.name,on:{"click":function () {
                _vm.selectPelotta(p.name)
              }}},[_c('v-list-item',[_c('v-chip',{staticClass:"mt-0",attrs:{"label":"","big":"","color":p.locked ? 'red' : 'transparent'}},[_vm._v(" "+_vm._s(_vm.getPelottaDescription(p))+" ")])],1)],1)}),1)],1):_vm._e(),_c('td',[_c('v-stage',{ref:"stage",attrs:{"config":{ width: _vm.width, height: _vm.height }},on:{"mousedown":_vm.handleStageMouseDown,"touchstart":_vm.handleStageMouseDown,"contextmenu":_vm.contextmenuDisable,"mousemove":_vm.handleStageMouseMove,"mouseup":_vm.handleStageMouseUp,"dblclick":_vm.handleStageDblClick}},[_c('v-layer',{ref:"bgLayer"},[(_vm.feetImage)?_c('v-image',{attrs:{"config":{
            width: _vm.width,
            height: _vm.height,
            image: _vm.feetImage,
            opacity: _vm.design.imageOpacity
          }}}):_vm._e()],1),_c('v-layer',{ref:"annotationLayer"},[_c('v-text',{attrs:{"config":{
          text: _vm.$t('left'),
          fontSize: 70,
          x: 20,
          y: 20,
          shadowBlur: 5,
          shadowOffsetX: 3,
          shadowOffsetY: 3,
          shadowColor: '#FFFFFF',
          shadowOpacity: 1.0,
        }}}),_c('v-text',{attrs:{"config":{
          text: _vm.$t('right'),
          fontSize: 70,
          x: _vm.width-200,
          y: 20,
          shadowBlur: 5,
          shadowOffsetX: 3,
          shadowOffsetY: 3,
          shadowColor: '#FFFFFF',
          shadowOpacity: 1.0,
        }}})],1),_c('v-layer',{ref:"pelottaLayer"},_vm._l((_vm.pelottaList),function(item,i){return _c('v-image',{key:i,attrs:{"config":item}})}),1)],1)],1),(!_vm.preview)?_c('td',{staticStyle:{"width":"200px"},attrs:{"valign":"bottom"}},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.getSortedPelottaListSide('right')),function(p){return _c('v-list-item',{key:p.name,on:{"click":function () {
                _vm.selectPelotta(p.name)
              }}},[_c('v-list-item',[_c('v-chip',{staticClass:"mt-0",attrs:{"label":"","big":"","color":p.locked ? 'red' : 'transparent'}},[_vm._v(" "+_vm._s(_vm.getPelottaDescription(p))+" ")])],1)],1)}),1)],1):_vm._e()])]),(!_vm.readonly)?_c('v-toolbar',{attrs:{"dark":"","color":"primary","height":"40px"}},[(_vm.selectedShape)?[_vm._v(" "+_vm._s(_vm.selectedShape.pelotta.name)+" "),_c('span',{staticClass:"ml-2",staticStyle:{"opacity":"0.5"}},[_vm._v(" "+_vm._s(_vm.$t("size-n", { n: _vm.selectedShape.pelotta.size }))+" ")])]:[_c('span',{staticClass:"mr-2",staticStyle:{"opacity":"0.5"}},[_vm._v(" "+_vm._s(_vm.$t("choose-an-item"))+" ")])],_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","color":"error","text":""},on:{"click":function($event){return _vm.$emit('quickSave')}}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")]),_c('v-select',{staticStyle:{"max-width":"200px"},attrs:{"hide-details":"","solo-inverted":"","flat":"","items":['color', 'bw', 'original'].map(function (value) { return ({ text: _vm.$t(value), value: value }); })},model:{value:(_vm.colorScheme),callback:function ($$v) {_vm.colorScheme=$$v},expression:"colorScheme"}})],1)],2):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }